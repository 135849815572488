.assetName {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 250px;
  max-width: 250px;
  height: 50px;
  text-align: center;
}

.assetCard {
  width: 250px;
  height: 250px;
}

.search {
  width: 300px;
}
