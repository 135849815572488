.hover-image-popup {
  position: relative;
  display: inline-block;
}

.thumbnail-image {
  width: 100px;
  cursor: pointer;
}
.product-image-overlay::after {
  content: none;
}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.fullscreen-image {
  max-width: 90%;
  max-height: 90%;
  border-radius: 8px;
}

.prev-button,
.next-button {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 20px; /* Increased padding for larger clickable area */
  cursor: pointer;
  font-size: 2rem;
  z-index: 1002; /* Higher z-index */
  height: 100%; /* Full vertical space */
  width: 15%; /* Adjusted for wider clickable area */
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prev-button {
  left: 0;
}

.next-button {
  right: 0;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 2rem;
  z-index: 1003; /* Higher z-index than other elements */
}

.prev-button:hover,
.next-button:hover,
.close-button:hover {
  background-color: rgba(0, 0, 0, 0.9);
}
