@tailwind base;
@tailwind components;

@layer utilities {
  body {
    font-family: 'Poppins', sans-serif;
  }
}

@tailwind utilities;
