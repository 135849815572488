.product:after {
  content: "...";
  display: inline-block;
  color: #000;
  font-size: 20px;
  position: relative;
  top: -102px;
  left: 122px;
}
.product {
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 4.4em;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  padding: 0 0.5em;
}

.star {
  visibility: hidden;
  font-size: 30px;
  cursor: pointer;
  color: orange;
}
.star:before {
  content: "\2606";
  position: absolute;
  visibility: visible;
}
.star:checked:before {
  content: "\2605";
  position: absolute;
}
.star:disabled {
  visibility: hidden;
  font-size: 30px;
  cursor: pointer;
  color: orange;
}

.star-selected:before {
  content: "\2605";
}
.star-selected {
  cursor: unset;
}
/* Heart Styles */
.heart {
  visibility: hidden;
  font-size: 30px;
  cursor: pointer;
  color: red;
}
.heart:before {
  content: "\2661"; /* Heart outline */
  position: absolute;
  visibility: visible;
}
.heart:checked:before {
  content: "\2665"; /* Filled heart */
  position: absolute;
}
.heart-selected:before {
  content: "\2665"; /* Filled heart */
}
.heart-selected {
  cursor: unset;
}
.group.featured td {
  background-color: #fef3c7;
}

.group.featured:hover {
  background-color: #fde68a;
}

.group.featured:hover td {
  background-color: #fde68a;
}
